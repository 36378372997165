package com.manoharan.stoneworks.components

import androidx.compose.runtime.Composable
import com.manoharan.stoneworks.theme.AccentColor
import com.manoharan.stoneworks.theme.BorderColor
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.util.Util
import com.manoharan.stoneworks.util.Util.FONT_NUNITO_SANS
import com.manoharan.stoneworks.util.Util.FONT_PATUA_ONE
import com.stevdza.san.kotlinbs.components.BSIcon
import com.stevdza.san.kotlinbs.icons.BSIcons
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div

@Composable
fun MainFooter(
    modifier: Modifier = Modifier,
    isBigScreen: Boolean,
) {

    Column(modifier = modifier) {

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 2),
            modifier = Modifier.fillMaxWidth()
        ){
            Column {
                Image(
                    src = "logo-main.png",
                    modifier = Modifier
                        .size(30.px)
                )
                SpanText(
                    text = "Address: Nesamani street, Mylaudy, Marungoor road, Nagercoil, Tamil Nadu 629403",
                    modifier = Modifier
                        .margin(topBottom = 8.px)
                        .fontSize(15.px)
                        .color(TextSecondaryColor)
                )
                SpanText(
                    text = "Manoharan Stone Works, 2023",
                    modifier = Modifier
                        .color(TextSecondaryColor)
                        .fontSize(14.px)
                        .fontFamily(FONT_NUNITO_SANS)
                        .textAlign(TextAlign.Center)
                        .fontWeight(FontWeight.Medium)
                )
            }

            Box(modifier = Modifier.fillMaxWidth()) {
                Button(
                    attrs = Modifier
                        .align(if (isBigScreen) Alignment.CenterEnd else Alignment.CenterStart)
                        .margin(top = 20.px)
                        .background(color = AccentColor)
                        .borderRadius(44.px)
                        .border(width = 0.px)
                        .padding(topBottom = 14.px, leftRight = 24.px)
                        .onClick {
                            window.open(url = "https://www.google.com")
                        }
                        .cursor(Cursor.Pointer)
                        .toAttrs(),
                ) {
                    SpanText(
                        text = "Contact now",
                        modifier = Modifier
                            .color(Colors.White)
                            .fontFamily(FONT_PATUA_ONE)
                    )
                }
            }
        }

        Div(
            attrs = Modifier
                .fillMaxWidth()
                .margin(topBottom = 20.px)
                .height(1.px)
                .backgroundColor(BorderColor)
                .toAttrs()
        )

        SpanText(
            text = "© 2023 Manoharan Stone Works Inc.",
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(12.px)
                .fontFamily(FONT_NUNITO_SANS)
                .textAlign(TextAlign.Center)
                .fontWeight(FontWeight.Medium)
        )

    }

}