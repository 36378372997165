package com.manoharan.stoneworks.pagecontent.products.content

import androidx.compose.runtime.Composable
import com.manoharan.stoneworks.data.DataUtil
import com.manoharan.stoneworks.data.globalProductsList
import com.manoharan.stoneworks.theme.AccentColor
import com.manoharan.stoneworks.theme.PrimaryBackgroundColor
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun BodyProducts(
    modifier: Modifier
) {

    val context = rememberPageContext()

    Column(modifier = modifier) {

        SpanText(
            text = "Products",
            modifier = Modifier
                .fontSize(20.px)
                .color(TextPrimaryColor)
        )
        SpanText(
            text = "Very satisfied customers and good sample goes here",
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(15.px)
                .fontFamily(Util.FONT_NUNITO_SANS)
        )

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 4),
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 24.px)
                .columnGap(24.px)
                .rowGap(24.px)
        ){

            globalProductsList.forEach { item ->

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .borderRadius(12.px)
                        .backgroundColor(PrimaryBackgroundColor)
                        .padding(bottom = 12.px)
                        .cursor(Cursor.Pointer)
                        .onClick {
                            context.router.navigateTo("/products/${item.id}")
                        }
                ) {
                    Image(
                        src = item.coverImage,
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(300.px)
                            .backgroundColor(Colors.White)
                            .borderRadius(topRight = 12.px, topLeft = 12.px)
                            .objectFit(ObjectFit.Cover)
                    )

                    SpanText(
                        text = item.title,
                        modifier = Modifier
                            .fontSize(16.px)
                            .padding(leftRight = 12.px, top = 12.px)
                            .color(TextPrimaryColor)
                    )

                    item.moreTitles.forEach { translatedTitle ->
                        SpanText(
                            text = translatedTitle,
                            modifier = Modifier
                                .padding(leftRight = 12.px,)
                                .fontSize(12.px)
                                .fontFamily(Util.FONT_NUNITO_SANS)
                                .fontWeight(FontWeight.SemiBold)
                                .color(TextSecondaryColor)
                        )
                    }

                }
            }
        }

    }

}