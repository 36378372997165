package com.manoharan.stoneworks.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.manoharan.stoneworks.components.MainFooter
import com.manoharan.stoneworks.components.TopNavigationBar
import com.manoharan.stoneworks.pagecontent.home.content.*
import com.manoharan.stoneworks.theme.PrimaryBackgroundColor
import com.manoharan.stoneworks.theme.TertiaryBackgroundColor
import com.manoharan.stoneworks.util.Util.FONT_PATUA_ONE
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun Home(){

    val coroutineScope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val isBigScreen = remember(key1 = breakpoint) { breakpoint > Breakpoint.MD }
    val baseLeftRightPadding = remember(key1 = breakpoint) {
        if (breakpoint < Breakpoint.LG) 20.px else 100.px
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            .fontFamily(FONT_PATUA_ONE)
    ) {

        // top-bar
        TopNavigationBar(
            modifier = Modifier
                .fillMaxWidth()
                .padding(topBottom = 12.px, leftRight = baseLeftRightPadding),
        )

        CoverHome(
            isBigScreen = isBigScreen,
            modifier = Modifier
                .fillMaxWidth()
                .padding(topBottom = 30.px, leftRight = baseLeftRightPadding),
        )

        ReviewsHome(
            isBigScreen = isBigScreen,
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 40.px)
                .padding(topBottom = 12.px, leftRight = baseLeftRightPadding),
        )

        ShowCaseBoardHome(
            isBigScreen = isBigScreen,
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(TertiaryBackgroundColor)
                .margin(top = 30.px)
                .padding(topBottom = 40.px, leftRight = baseLeftRightPadding),
        )

        OfferAdViewHome(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 30.px)
                .padding(topBottom = 40.px, leftRight = baseLeftRightPadding),
            isBigScreen = isBigScreen
        )

        TestimonialsHome(
            modifier = Modifier
                .fillMaxWidth()
                .padding(topBottom = 30.px, leftRight = baseLeftRightPadding),
        )
    }

    MainFooter(
        isBigScreen = isBigScreen,
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(PrimaryBackgroundColor)
            .padding(topBottom = 30.px, leftRight = baseLeftRightPadding)
            .margin(top = 50.px)
            .gridRowStart(2)
            .gridRowEnd(6)
    )
}