package com.manoharan.stoneworks.pagecontent.home.data

import androidx.compose.runtime.Stable
import com.manoharan.stoneworks.util.Util.getImageUrl


@Stable
val CoverWindowImage = getImageUrl(folder = "09", image = "image_0.jpg")

@Stable
val CoverEndImage = "https://www.home-design-eckstein.ch/wp-content/uploads/2018/06/Natursteinarbeiten-560x420.jpg"

@Stable
val OfferAdImage = "https://ammagardens.com/wp-content/uploads/2022/08/IMG-20220712-WA0021-1-1024x768-1-e1659423083515.jpg"