package com.manoharan.stoneworks.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.manoharan.stoneworks.components.MainFooter
import com.manoharan.stoneworks.components.TopNavigationBar
import com.manoharan.stoneworks.pagecontent.productdetail.content.BodyProductDetail
import com.manoharan.stoneworks.theme.PrimaryBackgroundColor
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PackageMapping
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/products/{product}")
@Composable
fun ProductDetail(){

    val breakpoint = rememberBreakpoint()
    val isBigScreen = remember(key1 = breakpoint) { breakpoint > Breakpoint.MD }
    val baseLeftRightPadding = remember(key1 = breakpoint) {
        if (breakpoint < Breakpoint.LG) 20.px else 100.px
    }

    val context = rememberPageContext()
    val productId = context.route.params.getValue("product")

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            .fontFamily(Util.FONT_PATUA_ONE)
    ) {

        // top-bar
        TopNavigationBar(
            modifier = Modifier
                .fillMaxWidth()
                .padding(topBottom = 12.px, leftRight = baseLeftRightPadding),
        )

        BodyProductDetail(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 12.px)
                .padding(topBottom = 12.px, leftRight = baseLeftRightPadding),
            isBigScreen = isBigScreen,
            productId = productId
        )

    }
    // footer
    MainFooter(
        isBigScreen = isBigScreen,
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(PrimaryBackgroundColor)
            .padding(topBottom = 30.px, leftRight = baseLeftRightPadding)
            .margin(top = 50.px)
            .gridRowStart(2)
            .gridRowEnd(6)
    )
}