package com.manoharan.stoneworks.pagecontent.about.content

import androidx.compose.runtime.Composable
import com.manoharan.stoneworks.theme.AccentColor
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun BodyAbout(
    modifier: Modifier,
    isBigScreen: Boolean
) {
    Column(modifier = modifier) {

        SpanText(
            text = "About",
            modifier = Modifier
                .fontSize(20.px)
                .color(TextPrimaryColor)
                .padding(bottom = 24.px)
        )

        Row(
            modifier = Modifier
                .fillMaxWidth(),
        ) {
            val imageSizeModifier = if (isBigScreen){
                Modifier.size(400.px)
            } else {
                Modifier.fillMaxWidth().minHeight(300.px)
            }

            Image(
                src = "https://firebasestorage.googleapis.com/v0/b/manoharan-stone-works.appspot.com/o/covers%2Fabout-manoharan-stoneworks.png?alt=media",
                modifier = Modifier
                    .then(imageSizeModifier)
            )

            if (isBigScreen) {
               AsideBody1(isBigScreen)
            }
        }

        if (!isBigScreen){
           AsideBody1(isBigScreen)
        }

        // grids
        SimpleGrid(
            numColumns = numColumns(base = 1, md = 2)
        ){
            Column {

            }
        }
    }
}


@Composable
private fun AsideBody1(
    isBigScreen: Boolean
) {

    val modifier = if (isBigScreen){
        Modifier.fillMaxWidth().padding(left = 30.px)
    } else {
        Modifier.fillMaxWidth().padding(top = 30.px)
    }

    Column(
        modifier = modifier
    ) {
        SpanText(
            text = "Established in 1977",
            modifier = Modifier
                .fontSize(20.px)
                .color(AccentColor)
                .padding(bottom = 4.px)
        )
        SpanText(
            text = "Welcome to Manoharan Stone Works 🙏🏻",
            modifier = Modifier
                .fontSize(20.px)
                .color(TextPrimaryColor)
                .padding(bottom = 4.px, top = 16.px)
        )
        SpanText(
            text = "For centuries, stone has been a cornerstone of human civilization, used for everything from " +
                    "building homes to crafting exquisite works of art. At Manoharan Stone Works, we are passionate about stone " +
                    "and its endless potential. We are committed to providing our customers with the highest quality stone " +
                    "products and services, backed by generations of experience and craftsmanship.\n" +
                    "\n" +
                    "Wholesale Stone Exports\n" +
                    "\n" +
                    "We are a leading exporter of wholesale stone products, sourcing our materials from the finest quarries " +
                    "around the world. Our extensive network of suppliers allows us to offer a wide variety of stones to meet " +
                    "the needs of our global clientele. Whether you are looking for , we " +
                    "have the perfect stone for your project.",
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(18.px)
                .fontFamily(Util.FONT_NUNITO_SANS)
        )
        SpanText(
            text = "We are committed to providing our customers with the highest quality stone products " +
                    "and services. We source our materials from the finest quarries around the world and use" +
                    " state-of-the-art equipment to ensure that our products are of the highest possible quality.",
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(18.px)
                .padding(top = 18.px)
                .fontFamily(Util.FONT_NUNITO_SANS)
        )
    }
}
