package com.manoharan.stoneworks.components

import androidx.compose.runtime.Composable
import com.manoharan.stoneworks.theme.White
import com.manoharan.stoneworks.util.NavigationItem
import com.stevdza.san.kotlinbs.components.BSNavBar
import com.stevdza.san.kotlinbs.models.BackgroundStyle
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.stevdza.san.kotlinbs.models.navbar.NavBarBrand
import com.stevdza.san.kotlinbs.models.navbar.NavBarButton
import com.stevdza.san.kotlinbs.models.navbar.NavLink
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.caretColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px

@Composable
internal fun TopNavigationBar(
    modifier: Modifier = Modifier
) {

    val context = rememberPageContext()

    BSNavBar(
        modifier = modifier
            .color(White)
            .caretColor(White)
            .backgroundColor(White),
        stickyTop = true,
        brand = NavBarBrand(
            title = "",
            href = "#",
            image = "logo-main.png",
            imageWidth = 40.px
        ),
        backgroundStyle = BackgroundStyle.Light,
        items = NavigationItem.entries.map { item ->
            NavLink(
                id = item.id,
                title = item.title,
                onClick = {
                    when (item.id) {
                        NavigationItem.Home.id -> {
                            context.router.navigateTo("/")
                        }

                        NavigationItem.Products.id -> {
                            context.router.navigateTo("/products")
                        }

                        NavigationItem.About.id -> {
                            context.router.navigateTo("/about")
                        }
                    }
                },
                active = if (item.id == NavigationItem.Home.id) {
                    context.route.slug == ""
                } else {
                    context.route.slug == item.id
                }
            )
        },
        itemsAlignment = Alignment.End,
        button = NavBarButton(
            text = "WhatsApp",
            onClick = {
                window.open(
                    url = "https://api.whatsapp.com/send?phone=+916369890540&text=" +
                            "Hi 👋🏻, I need some details about your stone products." +
                            "%0a%0a" +
                            "(Ref: Visit from Website)"
                )
            },
            variant = ButtonVariant.Success
        )
    )

}