package com.manoharan.stoneworks.pagecontent.home.content

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.manoharan.stoneworks.pagecontent.home.data.CoverEndImage
import com.manoharan.stoneworks.pagecontent.home.data.CoverWindowImage
import com.manoharan.stoneworks.theme.AccentColor
import com.manoharan.stoneworks.theme.PrimaryBackgroundColor
import com.manoharan.stoneworks.theme.SecondaryBackgroundColor
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button

@Composable
internal fun CoverHome(
    modifier: Modifier = Modifier,
    isBigScreen: Boolean
){

    Column(
        modifier = modifier
    ) {

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(topBottom = if (isBigScreen) 0.px else 30.px)
                .borderRadius(14.px)
                .background(color = PrimaryBackgroundColor),
            verticalAlignment = Alignment.CenterVertically
        ) {

            Segment1(isBigScreen = isBigScreen)

            if (isBigScreen) {
                Segment2()
            }
        }
    }
}

@Composable
private fun Segment1(
    isBigScreen: Boolean
) {

    val windowHeight = remember(key1 = isBigScreen) { if (isBigScreen) 300.px else 200.px }
    val windowWidth = remember(key1 = isBigScreen) { if (isBigScreen) 207.px else 138.px }
    val windowRadius = remember(key1 = isBigScreen) { (windowWidth.value/2).px }

    SimpleGrid(
        numColumns = numColumns(base = 1, md = 2),
        modifier = Modifier
            .fillMaxWidth(if (isBigScreen) 75.percent else 100.percent)
            .padding(left = if (isBigScreen) 80.px else 0.px)
        //verticalAlignment = Alignment.CenterVertically
    ) {
        Box(modifier = Modifier.fillMaxWidth()) {
            Box(
                modifier = Modifier
                    .align(Alignment.Center)
            ) {
                Box(
                    modifier = Modifier
                        .margin(top = 10.px)
                        .height(windowHeight)
                        .width(windowWidth)
                        .borderRadius(topLeft = windowRadius, topRight = windowRadius)
                        .backgroundColor(SecondaryBackgroundColor)
                )
                Image(
                    modifier = Modifier
                        .margin(left = 22.px)
                        .height(windowHeight)
                        .width(windowWidth)
                        .borderRadius(topLeft = windowRadius, topRight = windowRadius)
                        .objectFit(ObjectFit.Cover),
                    src = CoverWindowImage
                )
            }
        }

        val titleFontSize = remember(key1 = isBigScreen) {
            if (isBigScreen) 54.px else 40.px
        }

        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .align(Alignment.Center),
                horizontalAlignment = if (isBigScreen) Alignment.Start else Alignment.CenterHorizontally
            ) {

                SpanText(
                    text = "Manoharan",
                    modifier = Modifier
                        .fontSize(titleFontSize)
                )

                SpanText(
                    text = "Stone Works",
                    modifier = Modifier
                        .fontSize(titleFontSize)
                )


                Button(
                    attrs = Modifier
                        .margin(top = 20.px)
                        .background(color = AccentColor)
                        .borderRadius(44.px)
                        .border(width = 0.px)
                        .padding(topBottom = 17.px, leftRight = 24.px)
                        .onClick {
                            window.open(url = "https://www.google.com")
                        }
                        .cursor(Cursor.Pointer)
                        .toAttrs(),
                ) {
                    SpanText(
                        text = "Contact now",
                        modifier = Modifier
                            .color(Colors.White)
                    )
                }

            }
        }
    }
}

@Composable
private fun Segment2() {
    Image(
        src = CoverEndImage,
        modifier = Modifier
            .height(400.px)
            .fillMaxWidth(25.percent)
            .backgroundColor(color = Colors.DarkGray)
            .borderRadius(topRight = 14.px, bottomRight = 14.px)
    )
}