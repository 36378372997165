package com.manoharan.stoneworks.pagecontent.home.content

import androidx.compose.runtime.Composable
import com.manoharan.stoneworks.pagecontent.home.data.TestimonialData
import com.manoharan.stoneworks.pagecontent.home.data.testimonialList
import com.manoharan.stoneworks.theme.BorderColor
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun TestimonialsHome(
    modifier: Modifier = Modifier
) {

    Column(modifier = modifier) {

        SpanText(
            text = "Our Happy Customers",
            modifier = Modifier
                .fontSize(20.px)
                .color(TextPrimaryColor)
        )
        SpanText(
            text = "Very satisfied customers and good sample goes here",
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(15.px)
                .fontFamily(Util.FONT_NUNITO_SANS)
        )

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 3),
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 30.px)
                .columnGap(40.px)
                .rowGap(30.px)
        ){
            testimonialList.forEach { item ->
                TestimonialItem(
                    modifier = Modifier
                        .fillMaxWidth()
                        .borderRadius(12.px)
                        .border(
                            width = 1.px,
                            style = LineStyle.Solid,
                            color = BorderColor
                        )
                        .padding(topBottom = 60.px, leftRight = 40.px),
                    item = item
                )
            }
        }
    }
}

@Composable
private fun TestimonialItem(
    modifier: Modifier = Modifier,
    item: TestimonialData
){

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            src = item.image,
            modifier = Modifier
                .backgroundColor(Colors.LightGray)
                .size(80.px)
                .borderRadius(50.percent)
                .objectFit(ObjectFit.Cover)
        )
        SpanText(
            text = item.name,
            modifier = Modifier
                .margin(topBottom = 8.px)
                .fontSize(16.px)
                .color(TextPrimaryColor)
        )
        SpanText(
            text = item.testimonial,
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(14.px)
                .fontFamily(Util.FONT_NUNITO_SANS)
                .textAlign(TextAlign.Center)
                .fontWeight(FontWeight.Medium)
        )
    }

}