package com.manoharan.stoneworks.data

object DataUtil {

    data class ShowCaseItem(
        val id: String,
        val title: String,
        val moreTitles: List<String>,
        val image: String,
        val quantity: String,
    )

    val showCaseItemsList = globalProductsList.take(7) .map { productItem ->
        ShowCaseItem(
            id = productItem.id,
            title = productItem.title,
            moreTitles = productItem.moreTitles,
            image = productItem.coverImage,
            quantity = productItem.numberOfItemsAvailable
        )
    }
}