package com.manoharan.stoneworks.util

object Util {

    const val FONT_NUNITO_SANS = "Nunito Sans"
    const val FONT_PATUA_ONE = "Patua One"

    private const val FIREBASE_STORAGE_DOMAIN = "https://firebasestorage.googleapis.com/v0/b/manoharan-stone-works.appspot.com/o/"

    fun getImageUrl(folder: String, image: String): String {
        return FIREBASE_STORAGE_DOMAIN + "images%2F${folder}%2F${image}?alt=media"
    }
}