package com.manoharan.stoneworks.util

enum class NavigationItem(
    val id: String,
    val title: String
) {
    Home(
        id = "home",
        title = "Home"
    ),
    Products(
        id = "products",
        title = "Products"
    ),
    About(
        id = "about",
        title = "About"
    ),
    Contact(
        id = "blank",
        title = "    "
    ),
}