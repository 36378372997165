package com.manoharan.stoneworks.pagecontent.productdetail.content

import androidx.compose.runtime.*
import com.manoharan.stoneworks.data.globalProductsList
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun BodyProductDetail(
    modifier: Modifier,
    isBigScreen: Boolean,
    productId: String,
) {

    val context = rememberPageContext()
    val product = remember { globalProductsList.find { it.id == productId } }

    if (product == null) {
        SpanText(
            text = "Sorry, no product found ! ☹️",
            modifier = modifier
                .fontSize(24.px)
                .margin(top = 30.px)
                .color(TextPrimaryColor)
        )
        return
    }

    Column(modifier = modifier) {

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 2),
            modifier = Modifier
                .fillMaxWidth()
                .columnGap(80.px)
                .rowGap(24.px),
            content = {

                // section 1
                Image(
                    src = product.coverImage,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(if (isBigScreen) 400.px else 300.px)
                        .borderRadius(14.px)
                        .objectFit(ObjectFit.Cover)
                )


                val iconSize = remember { 24.px }

                // section 2
                Column {
                    SpanText(
                        text = product.title,
                        modifier = Modifier
                            .fontSize(24.px)
                            .color(TextPrimaryColor)
                    )

                    product.moreTitles.forEach { translatedTitle ->
                        SpanText(
                            text = translatedTitle,
                            modifier = Modifier
                                .fontSize(14.px)
                                .fontFamily(Util.FONT_NUNITO_SANS)
                                .fontWeight(FontWeight.SemiBold)
                                .color(TextSecondaryColor)
                        )
                    }

                    SpanText(
                        text = product.description,
                        modifier = Modifier
                            .color(TextSecondaryColor)
                            .fontSize(15.px)
                            .padding(top = 12.px)
                            .fontFamily(Util.FONT_NUNITO_SANS)
                    )
                    SpanText(
                        text = "Order Now? Contact us 👇🏻",
                        modifier = Modifier
                            .margin(topBottom = 20.px)
                            .fontSize(20.px)
                            .color(TextPrimaryColor)
                    )

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(
                            src = "/icons/call.svg",
                            modifier = Modifier.size(iconSize)
                        )
                        Link(
                            text = "+91 6369890540",
                            path = "tel:+916369890540",
                            modifier = Modifier
                                .margin(left = 12.px)
                                .fontSize(18.px)
                                .color(TextSecondaryColor)
                                .fontFamily(Util.FONT_NUNITO_SANS)
                                .fontWeight(FontWeight.Medium)
                                .cursor(Cursor.Pointer)
                                .textDecorationLine(TextDecorationLine.Underline)
                        )
                    }
                    Row(
                        modifier = Modifier.margin(top = 8.px),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(
                            src = "/icons/gmail.svg",
                            modifier = Modifier.size(iconSize)
                        )
                        Link(
                            text = "manoharanstoneworks@gmail.com",
                            path = "mailto:manoharanstoneworks@gmail.com?subject=Ref: Purchase from Website: ${product.title}",
                            modifier = Modifier
                                .margin(left = 12.px)
                                .fontSize(18.px)
                                .color(TextSecondaryColor)
                                .fontFamily(Util.FONT_NUNITO_SANS)
                                .fontWeight(FontWeight.Medium)
                                .cursor(Cursor.Pointer)
                                .textDecorationLine(TextDecorationLine.Underline)
                        )
                    }
                    Row(
                        modifier = Modifier.margin(top = 8.px),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(
                            src = "/icons/whatsapp.svg",
                            modifier = Modifier.size(iconSize)
                        )
                        Link(
                            text = "WhatsApp",
                            path = "https://api.whatsapp.com/send?phone=+916369890540&text=" +
                                    "Hi 👋🏻, Need some details on this product" +
                                    "%0a%0a" +
                                    "⚒️ Product: ${product.title}" +
                                    "%0a%0a" +
                                    "🌐 Link:${"https://manoharanstoneworks.com" + context.route}" +
                                    "%0a%0a" +
                                    "(Ref: Purchase from Website)",
                            modifier = Modifier
                                .margin(left = 12.px)
                                .fontSize(18.px)
                                .color(TextSecondaryColor)
                                .fontFamily(Util.FONT_NUNITO_SANS)
                                .fontWeight(FontWeight.Medium)
                                .cursor(Cursor.Pointer)
                                .textDecorationLine(TextDecorationLine.Underline)
                        )
                    }

                }
            }
        ) // grid end

        SpanText(
            text = "Gallery 😍",
            modifier = Modifier
                .margin(top = 24.px)
                .fontSize(20.px)
                .color(TextPrimaryColor)
        )

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 3),
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 24.px)
                .columnGap(50.px)
                .rowGap(24.px),
            content = {
                product.images.forEach { imageUrl ->

                    var imageFit by mutableStateOf(ObjectFit.Cover)

                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(if (isBigScreen) 400.px else 300.px)
                            .borderRadius(14.px)
                            .cursor(Cursor.Pointer)
                            .onMouseOver {
                                imageFit = ObjectFit.ScaleDown
                            }
                            .onMouseOut {
                                imageFit = ObjectFit.Cover
                            }
                    ) {
                        Image(
                            src = imageUrl,
                            modifier = Modifier
                                .fillMaxSize()
                                .borderRadius(14.px)
                                .objectFit(imageFit)
                        )
                    }
                }
            }
        )
    }

}