package com.manoharan.stoneworks.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page

@Page
@Composable
fun IndexPage() {
    Home()
}
