package com.manoharan.stoneworks.data

import androidx.compose.runtime.Immutable
import com.manoharan.stoneworks.util.Util.getImageUrl
import kotlin.js.Date


@Immutable
data class ProductsData(
    val products: List<ProductItem>
)

@Immutable
data class ProductItem(
    val id: String = Date.now().toString(),
    val title: String,
    val moreTitles: List<String> = emptyList(),
    val coverImage: String,
    val description: String,
    val images: List<String>,
    val numberOfItemsAvailable: String = "0", // need to decide
)

val globalProductsList = buildList {

    add(
        ProductItem(
            id = "01",
            title = "Wall Cladding Stone (Square)",
            moreTitles = listOf(
                "சுவர் உறை கல்",
                "വാൾ ക്ലാഡിംഗ് കല്ല്"
            ),
            coverImage = getImageUrl(folder = "01", image = "image_6.jpg"),
            description = "Elevate your space with our premium Wall Cladding Stone. Crafted from high-quality natural materials, our cladding offers durability and timeless elegance. Choose from a variety of textures and colors to suit any style. Transform interior and exterior walls into stunning focal points effortlessly. Explore our collection and redefine your space with authentic stone sophistication.",
            images = listOf(
                getImageUrl(folder = "01", image = "image_0.jpg"),
                getImageUrl(folder = "01", image = "image_1.jpg"),
                getImageUrl(folder = "01", image = "image_2.jpg"),
                getImageUrl(folder = "01", image = "image_3.jpg"),
                getImageUrl(folder = "01", image = "image_4.jpg"),
                getImageUrl(folder = "01", image = "image_5.jpg"),
                getImageUrl(folder = "01", image = "image_6.jpg"),
                getImageUrl(folder = "01", image = "image_7.jpg"),
                getImageUrl(folder = "01", image = "image_8.mp4"),
            ),
            numberOfItemsAvailable = "12"
        )
    )

    add(
        ProductItem(
            id = "05",
            title = "Natural Paving Stone (With Grass)",
            moreTitles = listOf(
                "நடைபாதை கல்",
                "തറക്കല്ല്"
            ),
            coverImage = getImageUrl(folder = "05", image = "image_6.jpg"),
            description = "Enhance your outdoor space with our exquisite Natural Paving Stones. Crafted from high-quality, ethically sourced materials, our paving stones boast timeless elegance and durability. Perfect for patios, walkways, and garden paths, each stone is unique, adding character and charm to any landscape design. With a variety of shapes, sizes, and colors available, you can create a customized look that reflects your personal style. Elevate your outdoor living experience with our Natural Paving Stones today.",
            images = listOf(
                getImageUrl(folder = "05", image = "image_0.jpg"),
                getImageUrl(folder = "05", image = "image_1.jpg"),
                getImageUrl(folder = "05", image = "image_2.jpg"),
                getImageUrl(folder = "05", image = "image_3.jpg"),
                getImageUrl(folder = "05", image = "image_4.jpg"),
                getImageUrl(folder = "05", image = "image_5.jpg"),
                getImageUrl(folder = "05", image = "image_6.jpg"),
                getImageUrl(folder = "05", image = "image_7.jpg"),
                getImageUrl(folder = "05", image = "image_8.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "15",
            title = "Stone Cross",
            moreTitles = listOf(
                "கல் குருசு",
                "കല്ല് കുരിശ്"
            ),
            coverImage = getImageUrl(folder = "15", image = "image_1.jpg"),
            description = "Introducing our exquisite Stone Cross, meticulously crafted with precision and care. Hand-carved from premium-quality stone, each cross boasts timeless beauty and enduring strength. Perfect for adding a touch of elegance to your garden, landscape, or interior space, our Stone Crosses serve as meaningful symbols of faith and spirituality. Whether used as a standalone statement piece or incorporated into a larger design, our Stone Crosses are sure to inspire reverence and admiration. Elevate your surroundings with the timeless charm of our artisanal Stone Crosses.",
            images = listOf(
                getImageUrl(folder = "15", image = "image_0.jpg"),
                getImageUrl(folder = "15", image = "image_1.jpg"),
                getImageUrl(folder = "15", image = "image_2.jpg"),
                getImageUrl(folder = "15", image = "image_3.jpg"),
                getImageUrl(folder = "15", image = "image_4.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "06",
            title = "Stone Bench (With Backrest)",
            moreTitles = listOf(
                "கல் பெஞ்ச்",
                "കല്ല് ബെഞ്ച്"
            ),
            coverImage = getImageUrl(folder = "06", image = "image_6.jpg"),
            description = "Elevate your outdoor space with our Stone Bench (With Backrest). Crafted with precision and durability in mind, this bench seamlessly blends functionality with timeless aesthetics. Made from high-quality stone, it offers both sturdiness and elegance, perfect for gardens, patios, or any outdoor setting. Its ergonomic design ensures comfort, while the backrest provides added support for relaxation. Enhance your landscape with a touch of sophistication and practicality with our Stone Bench (With Backrest).",
            images = listOf(
                getImageUrl(folder = "06", image = "image_0.jpg"),
                getImageUrl(folder = "06", image = "image_1.jpg"),
                getImageUrl(folder = "06", image = "image_2.jpg"),
                getImageUrl(folder = "06", image = "image_3.jpg"),
                getImageUrl(folder = "06", image = "image_4.jpg"),
                getImageUrl(folder = "06", image = "image_5.jpg"),
                getImageUrl(folder = "06", image = "image_6.jpg"),
                getImageUrl(folder = "06", image = "image_7.jpg"),
                getImageUrl(folder = "06", image = "image_8.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "06-a",
            title = "Stone Bench",
            moreTitles = listOf(
                "கல் பெஞ்ச்",
                "കല്ല് ബെഞ്ച്"
            ),
            coverImage = getImageUrl(folder = "06-a", image = "image_0.jpg"),
            description = "Elevate your outdoor space with our exquisite Stone Bench. Handcrafted from premium natural stone, each bench exudes timeless elegance and durability. Perfect for gardens, patios, or entryways, our stone bench offers a sturdy and inviting seating option that effortlessly blends with any landscape or architectural style. Enjoy moments of serenity and relaxation in style with our meticulously crafted Stone Bench.",
            images = listOf(
                getImageUrl(folder = "06-a", image = "image_0.jpg"),
                getImageUrl(folder = "06-a", image = "image_1.jpg"),
                getImageUrl(folder = "06-a", image = "image_2.jpg"),
                getImageUrl(folder = "06-a", image = "image_3.jpg"),
                getImageUrl(folder = "06-a", image = "image_4.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "04",
            title = "Pillar Light",
            moreTitles = listOf(
                "தூண் விளக்கு",
                "പില്ലർ ലൈറ്റ്"
            ),
            coverImage = getImageUrl(folder = "04", image = "image_0.jpg"),
            description = "Illuminate your outdoor space with timeless elegance using our Stone Pillar Light. Handcrafted from durable stone, this fixture combines rustic charm with modern functionality, providing ambient lighting while enhancing the natural beauty of your surroundings. Whether lining pathways, accentuating garden features, or adding a welcoming glow to your entrance, our Stone Pillar Light is a stylish and enduring addition to any landscape. Explore the perfect blend of craftsmanship and illumination for your outdoor oasis today.",
            images = listOf(
                getImageUrl(folder = "04", image = "image_0.jpg"),
                getImageUrl(folder = "04", image = "image_1.jpg"),
                getImageUrl(folder = "04", image = "image_2.jpg"),
                getImageUrl(folder = "04", image = "image_3.jpg"),
                getImageUrl(folder = "08", image = "image_0.jpg"),
                getImageUrl(folder = "08", image = "image_1.jpg"),
                getImageUrl(folder = "08", image = "image_2.jpg"),
                getImageUrl(folder = "08", image = "image_3.jpg"),
                getImageUrl(folder = "08", image = "image_4.jpg"),
                getImageUrl(folder = "08", image = "image_5.jpg"),
                getImageUrl(folder = "08", image = "image_6.jpg"),
                getImageUrl(folder = "08", image = "image_7.jpg"),
                getImageUrl(folder = "08", image = "image_8.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "09",
            title = "Stone Plant Pot",
            moreTitles = listOf(
                "கல் செடி தொட்டி",
                "കല്ല് ചേടി ചട്ടി"
            ),
            coverImage = getImageUrl(folder = "09", image = "image_0.jpg"),
            description = "Enhance your green space with our Stone Plant Pot, crafted with precision and care from high-quality stone materials. Our pots offer a blend of durability and elegance, seamlessly blending into any indoor or outdoor setting. Each piece is uniquely designed to complement various plant types, adding a touch of natural beauty to your surroundings. Elevate your gardening experience with our timeless Stone Plant Pot, where durability meets style.",
            images = listOf(
                getImageUrl(folder = "09", image = "image_0.jpg"),
                getImageUrl(folder = "09", image = "image_1.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "07",
            title = "Stone Murals",
            moreTitles = listOf(
                "கல் சுவரோவியம்",
                "കല്ല് ചുവർചിത്രം"
            ),
            coverImage = getImageUrl(folder = "07", image = "image_1.jpg"),
            description = "Transform your space with our captivating Stone Murals. Crafted with precision and passion, our stone artisans meticulously carve intricate designs into natural stone, creating stunning works of art that blend seamlessly with any environment. Elevate your home or business décor with timeless elegance and enduring beauty. Explore our collection and let our Stone Murals bring artistry and sophistication to your surroundings.",
            images = listOf(
                getImageUrl(folder = "07", image = "image_0.jpg"),
                getImageUrl(folder = "07", image = "image_1.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "11",
            title = "Wall Blocks & Bricks",
            moreTitles = listOf(
                "சுவர் கற்கள்",
                "മതിൽ കല്ല് ബ്ലോക്കുകൾ"
            ),
            coverImage = getImageUrl(folder = "11", image = "image_1.jpg"),
            description = "Introducing our exquisite collection of Wall Blocks & Bricks, meticulously crafted to elevate your stone works to new heights. Our premium-quality blocks and bricks boast unparalleled durability and timeless elegance, making them the perfect choice for enhancing any outdoor or indoor space. Whether you're creating stunning retaining walls, charming garden borders, or captivating architectural features, our Wall Blocks & Bricks offer endless possibilities for creative expression. Discover the epitome of craftsmanship and style with our versatile selection, designed to transform ordinary spaces into extraordinary works of art.",
            images = listOf(
                getImageUrl(folder = "11", image = "image_0.jpg"),
                getImageUrl(folder = "11", image = "image_1.jpg"),
                getImageUrl(folder = "11", image = "image_2.jpg"),
                getImageUrl(folder = "11", image = "image_3.jpg"),
                getImageUrl(folder = "11", image = "image_4.jpg"),
                getImageUrl(folder = "11", image = "image_5.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "12",
            title = "Stone Handrail & Pillars",
            moreTitles = listOf(
                "கல் கைப்பிடி & தூண்கள்",
                "കല്ലു കൈവരി & തൂണുകൾ"
            ),
            coverImage = getImageUrl(folder = "12", image = "image_0.jpg"),
            description = "Elevate your space with our exquisite Stone Handrail & Pillars. Crafted with precision and elegance, our handrails and pillars are meticulously carved from high-quality stone, adding a touch of timeless sophistication to any environment. Whether you seek to enhance the grandeur of your staircase or elevate the entrance of your property, our stone handrails and pillars are the perfect choice. Designed to withstand the test of time, they promise durability and charm for years to come. Explore our collection today and redefine the aesthetic allure of your surroundings with our stunning stone creations.",
            images = listOf(
                getImageUrl(folder = "12", image = "image_0.jpg"),
                getImageUrl(folder = "12", image = "image_1.jpg"),
                getImageUrl(folder = "12", image = "image_2.jpg"),
                getImageUrl(folder = "12", image = "image_3.jpg"),
                getImageUrl(folder = "12", image = "image_4.jpg"),
                getImageUrl(folder = "12", image = "image_5.jpg"),
                getImageUrl(folder = "12", image = "image_6.jpg"),
                getImageUrl(folder = "12", image = "image_7.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "12-a",
            title = "Stone barrier balls",
            moreTitles = listOf(
                "கல் தடை பந்துகள்",
                "സ്റ്റോൺ തടസ്സം ബോളുകൾ"
            ),
            coverImage = getImageUrl(folder = "12-a", image = "image_2.jpg"),
            description = "Introducing our Stone Barrier Balls – the perfect fusion of functionality and elegance for your landscape needs. Crafted with precision and finesse from high-quality stone, these barrier balls serve as both a practical solution and a decorative accent. Whether delineating pathways, defining borders, or enhancing the aesthetic appeal of your outdoor space, our Stone Barrier Balls offer durability and timeless charm. Elevate your landscape design with these versatile and eye-catching elements, meticulously crafted to withstand the test of time. Explore our range today and redefine your outdoor ambiance with Stone Barrier Balls.",
            images = listOf(
                getImageUrl(folder = "12-a", image = "image_0.jpg"),
                getImageUrl(folder = "12-a", image = "image_1.jpg"),
                getImageUrl(folder = "12-a", image = "image_2.jpg"),
                getImageUrl(folder = "12-a", image = "image_3.jpg"),
                getImageUrl(folder = "12-a", image = "image_4.jpg"),
                getImageUrl(folder = "12-a", image = "image_5.jpg"),
                getImageUrl(folder = "12-a", image = "image_6.jpg"),
                getImageUrl(folder = "12-a", image = "image_7.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "13",
            title = "Sun/Window shades",
            moreTitles = listOf(
                "கல் சன் ஷேட்ஸ்",
                "കല്ല്n സൺ ഷേഡുകൾ"
            ),
            coverImage = getImageUrl(folder = "13", image = "image_9.jpg"),
            description = "Introducing our innovative Sun/Window Shades crafted from natural stone. Combining functionality with exquisite craftsmanship, our stone shades offer unparalleled durability and timeless elegance. Designed to provide optimal sunlight control and insulation, they are perfect for both indoor and outdoor use. Transform any space with the unique beauty and earthy charm of our stone shades, enhancing your surroundings while offering protection from the elements. Explore our collection and bring a touch of natural sophistication to your home or office today.",
            images = listOf(
                getImageUrl(folder = "13", image = "image_0.jpg"),
                getImageUrl(folder = "13", image = "image_1.jpg"),
                getImageUrl(folder = "13", image = "image_2.jpg"),
                getImageUrl(folder = "13", image = "image_3.jpg"),
                getImageUrl(folder = "13", image = "image_4.jpg"),
                getImageUrl(folder = "13", image = "image_5.jpg"),
                getImageUrl(folder = "13", image = "image_6.jpg"),
                getImageUrl(folder = "13", image = "image_7.jpg"),
                getImageUrl(folder = "13", image = "image_8.jpg"),
                getImageUrl(folder = "13", image = "image_9.jpg"),
            )
        )
    )

    add(
        ProductItem(
            id = "14",
            title = "Natural Paving Stone (Paper Joint)",
            moreTitles = listOf(
                "நடைபாதை கல்",
                "തറക്കല്ല്"
            ),
            coverImage = getImageUrl(folder = "14", image = "image_5.jpg"),
            description = "Enhance your outdoor spaces with our exquisite Natural Paving Stone collection. Crafted from premium quality natural materials, our stones offer timeless elegance and durability for your landscaping projects. Each piece is uniquely shaped by nature, creating a charming and authentic aesthetic. Perfect for patios, pathways, and garden accents, our paving stones provide a versatile solution for transforming any outdoor area into a picturesque oasis. Explore our range today and elevate your landscape with the beauty of nature.",
            images = listOf(
                getImageUrl(folder = "14", image = "image_10.jpg"),
                getImageUrl(folder = "14", image = "image_11.jpg"),
                getImageUrl(folder = "14", image = "image_12.jpg"),
                getImageUrl(folder = "14", image = "image_0.jpg"),
                getImageUrl(folder = "14", image = "image_1.jpg"),
                getImageUrl(folder = "14", image = "image_2.jpg"),
                getImageUrl(folder = "14", image = "image_3.jpg"),
                getImageUrl(folder = "14", image = "image_4.jpg"),
                getImageUrl(folder = "14", image = "image_5.jpg"),
                getImageUrl(folder = "14", image = "image_6.jpg"),
                getImageUrl(folder = "14", image = "image_7.mp4"),
                getImageUrl(folder = "14", image = "image_8.mp4"),
                getImageUrl(folder = "14", image = "image_9.jpg"),
            )
        )
    )
}