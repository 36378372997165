package com.manoharan.stoneworks.pagecontent.home.content

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.manoharan.stoneworks.pagecontent.home.data.OfferAdImage
import com.manoharan.stoneworks.theme.*
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button

@Composable
fun OfferAdViewHome(
    modifier: Modifier = Modifier,
    isBigScreen: Boolean,
) {

    val windowHeight = remember(key1 = isBigScreen) { if (isBigScreen) 300.px else 200.px }
    val windowWidth = remember(key1 = isBigScreen) { if (isBigScreen) 207.px else 138.px }
    val windowRadius = remember(key1 = isBigScreen) { (windowWidth.value/2).px }

    Column (
        modifier = modifier
    ) {

        Box (
            modifier = Modifier
                .fillMaxWidth()
        ){
            // column
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 60.px, bottom = 60.px, left = if(isBigScreen) 130.px else 0.px)
                    .backgroundColor(TertiaryBackgroundColor)
                    .borderRadius(14.px),
                horizontalAlignment = if (isBigScreen) Alignment.Start else Alignment.CenterHorizontally
            ) {
                SpanText(
                    text = "Sale is Live 🔥",
                    modifier = Modifier
                        .fontSize(14.px)
                        .fontFamily(Util.FONT_NUNITO_SANS)
                        .color(TextSecondaryColor)
                )

                SpanText(
                    text = "Save upto 30% on all products",
                    modifier = Modifier
                        .fontSize(22.px)
                        .color(TextPrimaryColor)
                        .margin(top = 8.px)
                )
                Button(
                    attrs = Modifier
                        .margin(top = 8.px)
                        .background(color = AccentColor)
                        .borderRadius(44.px)
                        .border(width = 0.px)
                        .padding(topBottom = 10.px, leftRight = 24.px)
                        .onClick {
                            window.open(url = "https://www.google.com")
                        }
                        .cursor(Cursor.Pointer)
                        .toAttrs(),
                ) {
                    SpanText(
                        text = "Shop now",
                        modifier = Modifier
                            .color(Colors.White)
                    )
                }

                if (!isBigScreen){
                    Box(
                        modifier = Modifier
                            .margin(top = 30.px)
                    ) {
                        Box(
                            modifier = Modifier
                                .margin(left = 22.px, top = 10.px)
                                .height(windowHeight)
                                .width(windowWidth)
                                .borderRadius(bottomLeft = windowRadius, bottomRight = windowRadius)
                                .backgroundColor(SecondaryBackgroundColor)
                        )
                        Image(
                            modifier = Modifier
                                //.margin(top = 10.px)
                                .height(windowHeight)
                                .width(windowWidth)
                                .backgroundColor(Colors.LightGray)
                                .borderRadius(bottomLeft = windowRadius, bottomRight = windowRadius)
                                .objectFit(ObjectFit.Cover),
                            src = OfferAdImage
                        )
                    }
                }

            }

            // window shape - large
            if (isBigScreen) {
                Box(
                    modifier = Modifier
                        .align(Alignment.TopEnd)
                ) {
                    Box(
                        modifier = Modifier
                            .margin(left = 22.px, top = 10.px)
                            .height(windowHeight)
                            .width(windowWidth)
                            .borderRadius(bottomLeft = windowRadius, bottomRight = windowRadius)
                            .backgroundColor(SecondaryBackgroundColor)
                    )
                    Image(
                        modifier = Modifier
                            //.margin(top = 10.px)
                            .height(windowHeight)
                            .width(windowWidth)
                            .backgroundColor(Colors.LightGray)
                            .borderRadius(bottomLeft = windowRadius, bottomRight = windowRadius)
                            .objectFit(ObjectFit.Cover),
                        src = OfferAdImage
                    )
                }
            }
        }

    }

}