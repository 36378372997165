package com.manoharan.stoneworks.pagecontent.home.content

import androidx.compose.runtime.Composable
import com.manoharan.stoneworks.theme.AccentColor
import com.manoharan.stoneworks.theme.Black
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.util.Util.FONT_NUNITO_SANS
import com.manoharan.stoneworks.util.Util.FONT_PATUA_ONE
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun ReviewsHome(
    isBigScreen: Boolean,
    modifier: Modifier = Modifier
) {

    SimpleGrid(
        numColumns = numColumns(base = 1, md = 4),
        modifier = modifier
            .columnGap(16.px)
            .rowGap(24.px),
        content = {

            if (isBigScreen) {
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Div(
                        attrs = Modifier
                            .height(2.px)
                            .width(100.px)
                            .backgroundColor(Black)
                            .toAttrs()
                    )
                    SpanText(
                        text = "Our Speciality",
                        modifier = Modifier
                            .color(TextPrimaryColor)
                            .fontSize(17.px)
                            .margin(left = 20.px)
                    )
                }
            } else {
                Column(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    SpanText(
                        text = "Our Speciality",
                        modifier = Modifier
                            .color(TextPrimaryColor)
                            .fontSize(20.px)
                    )
                    Image(
                        modifier = Modifier
                            .width(180.px),
                        src = "underline_sketchy.svg"
                    )
                }
            }

            ReviewItem(
                modifier = Modifier
                    .padding(leftRight = 16.px),
                title = "100% Customization 🤩",
                message = "Our primary goal is to give full customization options on the product directly to the customers. You can almost decide how the product should look 100%"
            )
            ReviewItem(
                modifier = Modifier
                    .padding(leftRight = 16.px),
                title = "Top Notch Quality 🪄",
                message = "We are committed to providing our customers with the highest quality stone products and services. Come and explore the perfect stone for your project!"
            )
            ReviewItem(
                modifier = Modifier
                    .padding(leftRight = 16.px),
                title = "2000+ Satisfied customers ✅",
                message = "We love our customers❤️. Our Customer's satisfaction is the top most priority for us, We provide continuous support until the customer is fully satisfied."
            )

        }
    )
}

@Composable
private fun ReviewItem(
    modifier: Modifier = Modifier,
    title: String,
    message: String
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SpanText(
            text = title,
            modifier = Modifier
                .color(TextPrimaryColor)
                .fontSize(17.px)
                .textAlign(TextAlign.Center)
                .fontFamily(FONT_PATUA_ONE)
        )
        SpanText(
            text = message,
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(15.px)
                .textAlign(TextAlign.Center)
                .fontFamily(FONT_NUNITO_SANS)
        )
    }
}