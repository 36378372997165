package com.manoharan.stoneworks.theme

import com.varabyte.kobweb.silk.components.style.vars.color.BorderColorVar
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.rgb

val AccentColor = Color("#FC7821")
val PrimaryBackgroundColor = Color("#F1EAE3")
val SecondaryBackgroundColor = Color("#AF9997")
val TertiaryBackgroundColor = Color("#F5F3EF")
val DarkBackgroundColor = Color("#272E42")

val TextPrimaryColor = Color("#000000")
val TextSecondaryColor = Color("#686868")

val BorderColor = Color("#D9D9D9")

val Black = Color("#000000")
val White = Color("#FFFFFF")