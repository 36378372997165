package com.manoharan.stoneworks.pagecontent.home.content

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.web.events.SyntheticMouseEvent
import com.manoharan.stoneworks.theme.AccentColor
import com.manoharan.stoneworks.theme.TextPrimaryColor
import com.manoharan.stoneworks.theme.TextSecondaryColor
import com.manoharan.stoneworks.data.DataUtil.showCaseItemsList
import com.manoharan.stoneworks.theme.White
import com.manoharan.stoneworks.util.Util
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.px

@Composable
fun ShowCaseBoardHome(
    isBigScreen: Boolean,
    modifier: Modifier = Modifier
) {

    val context = rememberPageContext()

    Column(
       modifier = modifier
    ) {

        SpanText(
            text = "Making & Crafting",
            modifier = Modifier
                .fontSize(20.px)
                .color(TextPrimaryColor)
        )
        SpanText(
            text = "Very satisfied customers and good sample goes here",
            modifier = Modifier
                .color(TextSecondaryColor)
                .fontSize(15.px)
                .fontFamily(Util.FONT_NUNITO_SANS)
        )

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 4),
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 24.px)
                .columnGap(24.px)
                .rowGap(24.px)
        ){

            showCaseItemsList.forEach { item ->

                var imageFit by mutableStateOf(ObjectFit.Cover)

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .borderRadius(12.px)
                        .padding(bottom = 12.px)
                        .backgroundColor(Colors.White)
                        .cursor(Cursor.Pointer)
//                        .onMouseOver {
//                            imageFit = ObjectFit.Contain
//                        }
//                        .onMouseOut {
//                            imageFit = ObjectFit.Cover
//                        }
                        .onClick {
                            context.router.navigateTo("/products/${item.id}")
                        }
                ) {
                    Image(
                        src = item.image,
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(300.px)
                            .borderRadius(topRight = 12.px, topLeft = 12.px)
                            .objectFit(imageFit)
                    )

                    SpanText(
                        text = item.title,
                        modifier = Modifier
                            .fontSize(16.px)
                            .padding(leftRight = 12.px, top = 12.px)
                            .color(TextPrimaryColor)
                    )

                    item.moreTitles.forEach { translatedTitle ->
                        SpanText(
                            text = translatedTitle,
                            modifier = Modifier
                                .padding(leftRight = 12.px,)
                                .fontSize(12.px)
                                .fontFamily(Util.FONT_NUNITO_SANS)
                                .fontWeight(FontWeight.SemiBold)
                                .color(TextSecondaryColor)
                        )
                    }
                }
            }

            if (isBigScreen) {

                var viewMoreColor by mutableStateOf(White)

                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .borderRadius(12.px)
                        .padding(bottom = 12.px)
                        .backgroundColor(AccentColor)
                        .cursor(Cursor.Pointer)
                        .onClick {
                            context.router.navigateTo("/products")
                        }
                ) {
                    SpanText(
                        text = "View More 👈🏻",
                        modifier = Modifier
                            .fontSize(20.px)
                            .color(viewMoreColor)
                            .align(Alignment.Center)
                    )
                }
            } else {

                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(50.px)
                        .borderRadius(12.px)
                        //.padding(bottom = 12.px)
                        .backgroundColor(AccentColor)
                        .alignContent(AlignContent.Center),
                    contentAlignment = Alignment.Center
                ) {
                    SpanText(
                        text = "View More 👈🏻",
                        modifier = Modifier
                            .fontSize(20.px)
                            .color(White)
                            .align(Alignment.Center)
                            .onClick {
                                context.router.navigateTo("/products")
                            }
                    )
                }
            }
        }
    }

}