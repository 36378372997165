package com.manoharan.stoneworks.pagecontent.home.data

data class TestimonialData(
    val image: String,
    val name: String,
    val testimonial: String
)

val testimonialList = buildList {

    add(
        TestimonialData(
            image = "/testimonial/chandru.jpg",
            name = "Chandru M S",
            testimonial = "“There is no one who loves pain itself, who seeks after it " +
                    "and wants to have it, simply because it is no one who loves " +
                    "pain itself, who seeks after it and wants to have it”"
        )
    )

    add(
        TestimonialData(
            image = "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww",
            name = "Customer Name",
            testimonial = "“There is no one who loves pain itself, who seeks after it " +
                    "and wants to have it, simply because it is no one who loves " +
                    "pain itself, who seeks after it and wants to have it...”"
        )
    )

    add(
        TestimonialData(
            image = "https://www.bentbusinessmarketing.com/wp-content/uploads/2013/02/35844588650_3ebd4096b1_b-1024x683.jpg",
            name = "Customer Name",
            testimonial = "“There is no one who loves pain itself, who seeks after it " +
                    "and wants to have it, simply because it is no one who loves " +
                    "pain itself, who seeks after it and wants to have it...”"
        )
    )
}